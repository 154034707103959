import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hero from "../components/hero-standard";
import Nav from "../components/nav";
import Footer from "../components/footer";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Lead');
      }
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Simple package info requested" />
        <Nav activeTab="packages" />
        <div>
          <div key={`body`} id="___gatsby" dangerouslySetInnerHTML={{ __html: this.props.body }} />
          <div>

            <div>
              <Hero h1="Information requested" h2="We'll be in touch" />
              <div className="article">
                <div className='article-inner'>
                  <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                    Thank you for your request for more information about our Simple package.
                  </p>
                  <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                    A friendly member of our team will be in touch as soon as possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
